<template>
  <section
    id="founder"
  >
    <v-container
      class="fill-height"
    >
      <v-responsive
        class="align-center"
        height="100%"
        width="90%"
      >
        <!-- hide on screens smaller than -->
        <v-card class="hidden-xs-only">
          <v-col
            cols="10"
            class="d-flex flex-no-wrap justify-space-between"
          >
            <v-avatar
              class="ma-3"
              size="155"
              tile
            >
              <v-img
                class="rounded-img"
                :src="require('@/assets/damir.jpg')"
              />
            </v-avatar>
            <div>
              <v-card-title
                class="headline"
              >
                {{ $t('Founder.title') }}
              </v-card-title>

              <v-card-subtitle>
                <h3 class="light-blue--text">
                  {{ $t('Founder.subTitle') }}
                </h3>
                {{ $t('Founder.text') }}
              </v-card-subtitle>
            </div>
          </v-col>
        </v-card>
        <!-- for mobile -->
        <v-col class="d-sm-none">
          <v-row>
            <v-avatar
              
              size="155"
              tile
            >
              <v-img
              class="rounded-img"
                :src="require('@/assets/damir.jpg')"
              />
            </v-avatar>
          </v-row>
          <v-row>
            <v-card-title
              class="headline"
            >
              {{ $t('Founder.title') }}
            </v-card-title>
            <v-card-subtitle>
              <h3 class="light-blue--text">
                {{ $t('Founder.subTitle') }}
              </h3>
              {{ $t('Founder.text') }}
            </v-card-subtitle>
          </v-row>
        </v-col>
      </v-responsive>
    </v-container>
  </section>
</template>
<script>
  export default {
    name: 'Founder',
  }
</script>

<style>
  .rounded-img {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
</style>
